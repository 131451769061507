import React from 'react';
import { Link } from 'react-router-dom';
import lax from 'lax.js';
import LaxDiv from '../Shared/LaxDiv';
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
const images = [
    {
      "original": "/gallery/original/1L8A1805.jpg",
      "thumbnail": "/gallery/thumb/1L8A1805_tn.jpg"
    },
    {
      "original": "/gallery/original/1L8A1929.jpg",
      "thumbnail": "/gallery/thumb/1L8A1929_tn.jpg"
    },
    {
      "original": "/gallery/original/1L8A1934.jpg",
      "thumbnail": "/gallery/thumb/1L8A1934_tn.jpg"
    },
    {
      "original": "/gallery/original/1L8A1952.jpg",
      "thumbnail": "/gallery/thumb/1L8A1952_tn.jpg"
    },
    {
      "original": "/gallery/original/1L8A1955.jpg",
      "thumbnail": "/gallery/thumb/1L8A1955_tn.jpg"
    },
    {
      "original": "/gallery/original/1L8A2020.jpg",
      "thumbnail": "/gallery/thumb/1L8A2020_tn.jpg"
    },
    {
      "original": "/gallery/original/1L8A2031.jpg",
      "thumbnail": "/gallery/thumb/1L8A2031_tn.jpg"
    },
    {
      "original": "/gallery/original/1L8A2045.jpg",
      "thumbnail": "/gallery/thumb/1L8A2045_tn.jpg"
    },
    {
      "original": "/gallery/original/1L8A2075.jpg",
      "thumbnail": "/gallery/thumb/1L8A2075_tn.jpg"
    },
    {
      "original": "/gallery/original/1L8A2090.jpg",
      "thumbnail": "/gallery/thumb/1L8A2090_tn.jpg"
    },
    {
      "original": "/gallery/original/1L8A2107.jpg",
      "thumbnail": "/gallery/thumb/1L8A2107_tn.jpg"
    },
    {
      "original": "/gallery/original/1L8A2133.jpg",
      "thumbnail": "/gallery/thumb/1L8A2133_tn.jpg"
    },
    {
      "original": "/gallery/original/1L8A2198.jpg",
      "thumbnail": "/gallery/thumb/1L8A2198_tn.jpg"
    },
    {
      "original": "/gallery/original/1L8A2241.jpg",
      "thumbnail": "/gallery/thumb/1L8A2241_tn.jpg"
    },
    {
      "original": "/gallery/original/1L8A2249.jpg",
      "thumbnail": "/gallery/thumb/1L8A2249_tn.jpg"
    },
    {
      "original": "/gallery/original/1L8A2260.jpg",
      "thumbnail": "/gallery/thumb/1L8A2260_tn.jpg"
    },
    {
      "original": "/gallery/original/1L8A2317.jpg",
      "thumbnail": "/gallery/thumb/1L8A2317_tn.jpg"
    },
    {
      "original": "/gallery/original/1L8A2353.jpg",
      "thumbnail": "/gallery/thumb/1L8A2353_tn.jpg"
    },
    {
      "original": "/gallery/original/1L8A2358.jpg",
      "thumbnail": "/gallery/thumb/1L8A2358_tn.jpg"
    },
    {
      "original": "/gallery/original/1L8A2372.jpg",
      "thumbnail": "/gallery/thumb/1L8A2372_tn.jpg"
    },
    {
      "original": "/gallery/original/1L8A2375.jpg",
      "thumbnail": "/gallery/thumb/1L8A2375_tn.jpg"
    },
    {
      "original": "/gallery/original/Copy of 1L8A2017.jpg",
      "thumbnail": "/gallery/thumb/Copy of 1L8A2017_tn.jpg"
    },
    {
      "original": "/gallery/original/HBR_2971.jpg",
      "thumbnail": "/gallery/thumb/HBR_2971_tn.jpg"
    },
    {
      "original": "/gallery/original/HBR_2978.jpg",
      "thumbnail": "/gallery/thumb/HBR_2978_tn.jpg"
    },
    {
      "original": "/gallery/original/HBR_2989.jpg",
      "thumbnail": "/gallery/thumb/HBR_2989_tn.jpg"
    },
    {
      "original": "/gallery/original/HBR_3010.jpg",
      "thumbnail": "/gallery/thumb/HBR_3010_tn.jpg"
    },
    {
      "original": "/gallery/original/HBR_3112.jpg",
      "thumbnail": "/gallery/thumb/HBR_3112_tn.jpg"
    },
    {
      "original": "/gallery/original/HBR_3192.jpg",
      "thumbnail": "/gallery/thumb/HBR_3192_tn.jpg"
    },
    {
      "original": "/gallery/original/HBR_3228.jpg",
      "thumbnail": "/gallery/thumb/HBR_3228_tn.jpg"
    },
    {
      "original": "/gallery/original/HBR_3229.jpg",
      "thumbnail": "/gallery/thumb/HBR_3229_tn.jpg"
    },
    {
      "original": "/gallery/original/HBR_3238.jpg",
      "thumbnail": "/gallery/thumb/HBR_3238_tn.jpg"
    },
    {
      "original": "/gallery/original/HBR_3242.jpg",
      "thumbnail": "/gallery/thumb/HBR_3242_tn.jpg"
    },
    {
      "original": "/gallery/original/HBR_3249.jpg",
      "thumbnail": "/gallery/thumb/HBR_3249_tn.jpg"
    },
    {
      "original": "/gallery/original/HBR_3251.jpg",
      "thumbnail": "/gallery/thumb/HBR_3251_tn.jpg"
    },
    {
      "original": "/gallery/original/HBR_3261.jpg",
      "thumbnail": "/gallery/thumb/HBR_3261_tn.jpg"
    },
    {
      "original": "/gallery/original/HBR_3268.jpg",
      "thumbnail": "/gallery/thumb/HBR_3268_tn.jpg"
    },
    {
      "original": "/gallery/original/HBR_3277.jpg",
      "thumbnail": "/gallery/thumb/HBR_3277_tn.jpg"
    }
  ];
  
class Gallery extends React.Component {

    constructor(props) {
        super(props)
        lax.setup()
    
        document.addEventListener('scroll', function(x) {
            lax.update(window.scrollY)
        }, false)
    
        lax.update(window.scrollY)
    }

    openTabSection = (evt, tabNmae) => {
        let i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tabs_item");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }

        tablinks = document.getElementsByTagName("li");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace("current", "");
        }

        document.getElementById(tabNmae).style.display = "block";
        evt.currentTarget.className += "current";
    }

    render(){
        return (
            <section id="recap" className="schedule-area bg-image ptb-120">
                <div className="container">
                    <div className="section-title">
                        {/* <span>Schedule Plan</span> */}
                        <h2 style={{textAlign:"center"}}>Glimpses of Jamboree 2022</h2>

                        {/* <LaxDiv text="Events" dataPreset="driftLeft" /> */}

                        {/* <Link to="#" className="btn btn-primary">Register Now!</Link> */}

                        {/* <div className="bar"></div> */}
                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                        <ImageGallery lazyLoad={true} showPlayButton={false} items={images} />
                        </div>

                        {/* <div className="col-lg-12">
                            <div className="btn-box">
                                <Link to="#" className="btn btn-primary">Download Schedule (PDF)</Link>
                                <Link to="#" className="btn btn-secondary">Connect Via Instagram</Link>
                            </div>
                        </div> */}
                    </div>
                </div>

                </section>
        );
    }
}
 
export default Gallery;