import React from "react";
import { Link } from "react-router-dom";

class WhyUs extends React.Component {
  render() {
    return (
      <>
        {/* <section className="about-area ptb-120 bg-image" id="whyus">
          <div className="container">
            <div className="row h-100 align-items-center">
              <div className="about-content" style={{ fontSize: 18 }}>
                <p style={{ fontSize: 15 }}>
                  Making money and staying relevant and ahead of your
                  competitors is what it’s all about. Partnering with Hitachi
                  Vantara brings together the best of both worlds. Leading
                  technology, rich incentives for greater profitability, with
                  collaboration and teamwork to help you succeed.
                </p>
                <p style={{ fontSize: 15 }}>
                  At Hitachi Vantara, we give our partners the front-row access
                  to emerging technologies like cloud, object storage, Internet
                  of Things (IoT) and much more.
                </p>

                <p style={{ fontSize: 15 }}>
                  Our offerings help customers adapt to the needs of workloads
                  of the future with a modernized edge-to-core-to-cloud
                  infrastructure that delivers agility, efficiency, and
                  resiliency. Take an integrated, strategic approach to
                  application modernization, data modernization and
                  infrastructure modernization. Work from this digital
                  foundation to innovate, evolve and deliver exceptional
                  experiences for our customers.
                </p>

                <p style={{ fontSize: 15 }}>
                  As our valued partner, you will have the chance to expand your
                  value by leveraging the Hitachi Vantara offerings.
                </p>
              </div>
            </div>
          </div>
          
        </section> */}
        <section className="why-choose-us" id="whyus1">
          <div className="row m-0">
            <div className="col-lg-4 col-sm-6 p-0">
              <div className="single-box">
                <div className="d-table">
                  <div className="d-table-cell">
                    <div className="content">
                      <div className="icon">
                        <i className="icofont-headphone-alt-1"></i>
                      </div>
                      <h3>Live Entertainment & music</h3>
                      <p>
                      Immerse yourself in a world of live entertainment and music that will ignite your senses and elevate your experience.                      </p>

                      {/* <Link to="#" className="btn btn-primary">Read More</Link> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 p-0">
              <div className="single-box">
                <div className="d-table">
                  <div className="d-table-cell">
                    <div className="content">
                      <div className="icon">
                        <i className="icofont-network-tower"></i>
                      </div>
                      <h3>Exciting Activities and Photo Booths </h3>
                      <p >
                      Capture unforgettable moments and create lasting memories with our thrilling activities and interactive photo booths.                      </p>

                      {/* <Link to="#" className="btn btn-primary">Read More</Link> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="col-lg-3 col-sm-6 p-0">
                        <div className="single-box">
                            <div className="d-table">
                                <div className="d-table-cell">
                                    <div className="content">
                                        <div className="icon">
                                            <i className="icofont-users-social"></i>
                                        </div>
                                        <h3>New People</h3>
                                        <p>Tap into the opportunity to meet and make direct connections with peers, partners, leaders, and Hitachi Vantara experts. </p>
            
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

            <div className="col-lg-4 col-sm-6 p-0">
              <div className="single-box">
                <div className="d-table">
                  <div className="d-table-cell">
                    <div className="content">
                      <div className="icon">
                        <i className="icofont-wink-smile"></i>
                      </div>
                      <h3>Fun & Bonding</h3>
                      <p>
                      Experience the perfect blend of fun, bonding, and personal growth through a variety of exciting activities.
                      </p>

                      {/* <Link to="#" className="btn btn-primary">Read More</Link> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ul className="slideshow">
            <li>
              <span></span>
            </li>
            {/* <li>
              <span></span>
            </li>
            <li>
              <span></span>
            </li> */}
           
          </ul>
        </section>
      </>
    );
  }
}

export default WhyUs;
