import React from "react";
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { history } from "../../history";
import firebase from "firebase";
import { Spinner } from "reactstrap";
import { isMobile, withOrientationChange } from "react-device-detect";
import Select from "react-select";
import { Form, FormGroup } from "reactstrap";
import DatePicker from "react-datepicker";
import Moment from "moment";

// import 'react-datetime-picker/dist/DateTimePicker.css';
import "react-datepicker/dist/react-datepicker.css";

// import 'react-calendar/dist/Calendar.css';
// import 'react-clock/dist/Clock.css';
class Registerform extends React.Component {
  state = {
    name: "",
    email: "",
    password: "",
    city: "",
    position: "",
    company: "",
    phone: "",
    isloading: false,
    successAlert: false,
    errorAlert: false,
    errormsg: "",
    relation:null,
    category: null,
    startDate: new Date("2023/07/12"),
    checkoutDate: new Date("2023/07/14"),
    tshirt: null,
  };
  // const [value, onChange] = useState(new Date());
  onChange = (data) => {
    this.setState({ startDate: data });
  };
  handleAlert = (state, value) => {
    this.setState({ [state]: value });
    // history.push("/login");
  };
  handleAlert2 = (state, value) => {
    this.setState({ [state]: value });
  };
  onSubmit = (e) => {
    e.preventDefault();
    if (this.state.plusone==="Yes"&&this.state.relation === null) {
      this.setState({
        errorAlert: true,
        errormsg: "Please select Companion Relation.",
      });
    }
    // else if (this.state.email.endsWith("@framez.sg")&&this.state.email.endsWith("@netradyne.com")) {
    //   this.setState({
    //     errorAlert: true,
    //     errormsg: "Please use your Company Email Id.",
    //   });
    // } 
    else {
      this.setState({
        isloading: true,
      });
      var registeruser = firebase
        .app()
        .functions("asia-east2")
        .httpsCallable("createuser");
      registeruser({
        name: this.state.name,
        lastname: this.state.lastname,
        // phone: this.state.phone,
        // email: this.state.email,
        time: new Date().toString(),
        plusone: this.state.plusone,
        // compfirstname: this.state.compfirstname?this.state.compfirstname:"",
        // complastname: this.state.complastname?this.state.complastname:"",
        relation: this.state.relation?this.state.relation.value:"",
        // password: this.state.password
      }).then((data) => {
        console.log(data);
        console.log(data.data.err);
        console.log(JSON.stringify(data));
        if (data.data.err == 0) {
          console.log("REgister done");
          this.setState({
            successAlert: true,
            isloading: false,
            name:"",
            lastname:"",
            relation:null,
            phone:"",
            email:"",
            
            compfirstname:"",
            complastname:"",
          });
          // history.push("/login");
        } else if (data.data.err === 1) {
          console.log("Already registered");
          this.setState({
            errorAlert: true,
            errormsg: "Already Registered",
            isloading: false,
          });
        } else {
          console.log("There was an error");
          this.setState({
            errorAlert: true,
            errormsg: "There was an error",
            isloading: false,
          });
        }
      });
    }
  };
  render() {
    return (
      <section className="signup-area">
        <SweetAlert
          success
          title="Success"
          show={this.state.successAlert}
          onConfirm={() => this.handleAlert("successAlert", false)}
        >
          <p style={{ color: "#000" }} className="sweet-alert-text">
            Thank you for registering. Please check your mail.
          </p>
        </SweetAlert>

        <SweetAlert
          title="Error"
          show={this.state.errorAlert}
          onConfirm={() => this.handleAlert2("errorAlert", false)}
        >
          <p style={{ color: "#000" }} className="sweet-alert-text">
            {this.state.errormsg}
          </p>
        </SweetAlert>
        <div>
          <div>
            <h1 style={{textAlign:"center"}}>Registrations are closed Now!!</h1>
          </div>
          <div className="signup-form" style={{display:"none"}}>
            {/* <h3>Create your Account</h3> */}
            {/* <h3>Registrations are now closed for the summit</h3> */}
            <form onSubmit={this.onSubmit}>
              <div className="form-group">
                {/* <label>Name</label> */}
                <input
                  required
                  type="text"
                  className="form-control"
                  placeholder="First Name"
                  value={this.state.name}
                  onChange={(e) => this.setState({ name: e.target.value })}
                />
              </div>
              <div className="form-group">
                {/* <label>Name</label> */}
                <input
                  required
                  type="text"
                  className="form-control"
                  placeholder="Last Name"
                  value={this.state.lastname}
                  onChange={(e) => this.setState({ lastname: e.target.value })}
                />
              </div>

              {/* <div className="form-group">
                <input
                  required
                  type="email"
                  className="form-control"
                  placeholder="Email Address"
                  value={this.state.email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                />
              </div> */}
              {/* <div className="form-group">
                <input
                  required
                  type="text"
                  className="form-control"
                  placeholder="Mobile Number"
                  value={this.state.phone}
                  onChange={(e) => this.setState({ phone: e.target.value })}
                />
              </div> */}
            
              <div className="form-group">
                <label style={{marginRight:20}}>Interested in bringing your dear one along? </label>
                <div class="form-check form-check-inline">
                  <input
                  required
                  onChange={(e) => this.setState({ plusone: "Yes" })}
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    checked={this.state.plusone === "Yes"}
                    id="inlineRadio1"
                    value="option1"
                  />
                  <label class="form-check-label" for="inlineRadio1">
                  Yes
                  </label>
                </div>

                <div class="form-check form-check-inline">
                  <input
                   onChange={(e) => this.setState({ plusone: "No" })}
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    checked={this.state.plusone === "No"}
                    id="inlineRadio2"
                    value="option2"
                  />
                  <label class="form-check-label" for="inlineRadio2">
                    No
                  </label>
                </div>
              </div>
              {/* <div className="form-group">
                <input
                  required
                  type="text"
                  className="form-control"
                  placeholder="Job Title"
                  value={this.state.position}
                  onChange={(e) => this.setState({ position: e.target.value })}
                />
              </div> */}
              {this.state.plusone==="Yes"?
              <>
                {/* <div className="form-group">
                <input
                  required
                  type="text"
                  className="form-control"
                  placeholder="Companion First Name"
                  value={this.state.compfirstname}
                  onChange={(e) => this.setState({ compfirstname: e.target.value })}
                />
              </div>
              <div className="form-group">
                <input
                  required
                  type="text"
                  className="form-control"
                  placeholder="Companion Last Name"
                  value={this.state.complastname}
                  onChange={(e) => this.setState({ complastname: e.target.value })}
                />
              </div> */}
            
              <div className="form-group">
                {/* <label>Category</label> */}
                <Select
                  required
                  className="React"
                  classNamePrefix="select"
                  value={this.state.relation}
                  onChange={(a) => {
                    this.setState({
                      relation: a,
                    });
                  }}
                  placeholder="Relation"
                  options={[
                    {
                      value: "Family",
                      label: "Family",
                    },
                    {
                      value: "Friend",
                      label: "Friend",
                    }
                  ]}
                />
                {/* <Label>Company size </Label> */}
              </div>
             
            
              </>:null}
            

              <button
                disabled={this.state.isloading}
                type="submit"
                style={{ zIndex: 0 }}
                className="btn btn-primary"
              >
                {this.state.isloading ? <Spinner color="red" /> : "Register"}
              </button>

              {/* <p>
                  Already a registered user? <Link to="/login">Login!</Link>
                </p> */}
            </form>
          </div>
          {/* </div> */}
        </div>
      </section>
    );
  }
}

export default Registerform;
