import React from "react";
import { Link } from "react-router-dom";

class Footer extends React.Component {
  render() {
    return (
      <>
        <section className="about-area ptb-120 bg-image" id="about1">
          <div className="container">
            <div className="row h-100 align-items-center">
              <div className="col-lg-6">
                <div
                  className="about-content"
                  style={{ fontSize: 18, textAlign: "center" }}
                >
                  <h3>
                    Join us at Sheraton Grand Bengaluru Whitefield Hotel &
                    Convention Center
                  </h3>
                  <br />
                  <p style={{ fontSize: 18 }}>
                    Thrive at our elevated and efficient Sheraton Grand
                    Bengaluru Whitefield Hotel & Convention Center. Located in
                    Bangalore, India, our five-star hotel is ideally located
                    near many major corporations, IT parks and tourist spots
                    such as Bangalore Palace and Phoenix Mall.
                  </p>
                  {/* <button
                    to="/pricing-1"
                    className="btn btn-primary"
                    onClick={() => {
                      window.open(
                        "https://www.marriott.com/en-us/hotels/blrsw-sheraton-grand-bengaluru-whitefield-hotel-and-convention-center/overview/"
                      );
                    }}
                  >
                    MORE ABOUT THE HOTEL  
                  </button> */}
                </div>
              </div>

              <div className="col-lg-6">
                <div className="about-image">
                  {/* <img src="./hotel1.jpg" className="about-img1" alt="about" /> */}

                  <img src="./hotel3.jpg" className="demo" alt="about" />

                  {/* <img src={require("../../assets/images/shapes/5.png")} className="shape-img" alt="about" /> */}

                  {/* <LaxButton buttonText="Explore More About" /> */}
                </div>
              </div>
            </div>
          </div>
        </section>

        <footer className="footer-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div
                  className="single-footer-widget"
                  style={{
                    color: "#3DB174",
                    fontSize:18
                    // display: "flex",
                    // justifyContent: "center",
                    // flexDirection: "column",
                    // alignItems: "center",
                  }}
                >
                  <h3 style={{ color: "#3DB174" }}>Venue</h3>
                  <span style={{ color: "#3DB174" }}>
                    <i
                      style={{ color: "#3DB174" }}
                      className="icofont-calendar"
                    ></i>{" "}
                    4th November, 2023
                  </span>

                  <p className="location" style={{ color: "#3DB174",fontSize:18 }}>
                    <i className="icofont-google-map"></i> Sheraton Grand
                    Bengaluru Whitefield Hotel & Convention Center
                  </p>

                  <a
                    to="/contact"
                    style={{ color: "#3DB174" }}
                    className="contact-authority"
                  >
                    <i className="icofont-phone"></i> For any support, write to:
                    <br />
                    <a  style={{ color: "#3DB174",marginLeft:5 }} href="mailto:Shanti.k@framez.sg">Shanti.k@framez.sg</a>
                    {/* <br />
                    <a href="mailto:support@echoo.in">
                      support@echoo.in
                    </a> */}
                  </a>
                </div>
              </div>

              <div className="col-lg-6 col-md-6" style={{ display: "none" }}>
                <div className="single-footer-widget">
                  <h3>Social Connection</h3>
                  {/* <p>Don't miss Link thing! Receive daily news You should connect social area for Any Proper Updates Anytime.</p> */}

                  <ul className="social-links">
                    <li>
                      <a
                        href="https://www.facebook.com/HitachiVantara"
                        className="facebook"
                        target="_blank"
                      >
                        <i className="icofont-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/HitachiVantara"
                        className="twitter"
                        target="_blank"
                      >
                        <i className="icofont-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/hitachi-vantara/"
                        className="linkedin"
                        target="_blank"
                      >
                        <i className="icofont-linkedin"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/c/HitachiVantara"
                        className="youtube"
                        target="_blank"
                      >
                        <i className="icofont-youtube-play"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="copyright-area">
                  {/* <div className="logo">
                    <a
                      href="https://www.hitachivantara.com/"
                      target="_blank"
                      to="/"
                    >
                      <img
                        src={require("../../assets/images/hitachi4.png")}
                        width={200}
                        alt="logo"
                      />
                    </a>
                  </div> */}
                  {/* <ul>
                                    <li><Link to="/blog-1">Blog</Link></li>
                                    <li><Link to="/contact">Contact</Link></li>
                                    <li><Link to="#">Ticket</Link></li>
                                    <li><Link to="#">Venue</Link></li>
                                    <li><Link to="#">Terms & Conditions</Link></li>
                                </ul> */}
                  <p style={{ color: "#3DB174" }}>
                    Copyright <i className="icofont-copyright"></i> 2023 Framez.
                    All rights reserved
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
}

export default Footer;
