import React from 'react';
import MainBanner from '../HomeDefault/MainBanner';
import About from '../HomeDefault/About';
import WhyUs from '../Common/WhyUs';
import Speakers from "../HomeThree/Speakers";
import Speakers2 from "../HomeFour/Speakers";
import { useLocation } from 'react-router-dom';

import GoTop from '../Shared/GoTop';
import Footer from '../Common/Footer';
import EventSchedules from '../HomeDefault/EventSchedules';
import FunFact from '../Common/FunFact';
import Pricing from '../HomeDefault/Pricing';
import lax from 'lax.js';
import Partner from '../Common/Partner';
import LatestNews from '../HomeDefault/LatestNews';
import BuyTicket from '../Common/BuyTicket';
import Subscribe from '../Common/Subscribe';
import Preloader from "../../components/Shared/Preloader";
import firebase from 'firebase';
import { history } from "../../history";
import Navigation from '../Navigation/Navigation';
import Sponsor from './Sponsor';
import Gallery from '../HomeDefault/Gallery';
import Video from '../HomeDefault/Video';
import ReactPlayer from "react-player";

import {
    Modal,
    ModalBody,
    ModalHeader
  } from "reactstrap";
class HomeDefault extends React.Component {
    constructor(props) {
        super(props)
        lax.setup()
    
        document.addEventListener('scroll', function(x) {
            lax.update(window.scrollY)
        }, false)
    
        lax.update(window.scrollY)
    }
    componentDidMount(){
        // if(firebase.auth().currentUser){
          
        // }else{
        //     console.log("Not logged in1");
        //     history.push("/signup")
        // }
        // var hash = window.location.hash.replace("#","");
        // let hero = document.getElementById(hash);
        //               console.log(hero);
        //               hero &&
        //                 hero.scrollIntoView({
        //                   behavior: "smooth",
        //                   block: "start",
        //                   inline:"end"
        //                 });
        // var location = useLocation();
        // const myQuery  = location.search;
        // console.log(location)

    }
    render(){
        return (
            <React.Fragment>
                
                  {/* <img
                    src={require("../../assets/images/banner4.jpg")}
                    width={"100%"}
                    style={{
                    
                    }}
                  /> */}
                <Navigation/>
                {/* Main Banner */}
                <MainBanner />
                {/* About Area */}
                <Speakers />
               
                <Video/>
                <EventSchedules />
                <WhyUs />
                {/* <Speakers2/> */}
                <About />
              
               
                {/* Why Choose Us Area */}
              
                {/* Speakers Area */}
                
                {/* Schedule Area */}
               
                <Gallery/>
                {/* <Sponsor/> */}
                <Footer/>
                {/* FunFacts Area */}
                {/* <FunFact /> */}
                {/* Pricing Area */}
                {/* <Pricing /> */}
                {/* Partner Area */}
                {/* <Partner /> */}
                {/* Blog Area */}
                {/* <LatestNews /> */}
                {/* Registers Area */}
                {/* <BuyTicket /> */}
                {/* Subscribe Area */}
                {/* <Subscribe /> */}

                {/* <Footer /> */}

                {/* Back Top top */}
                <GoTop scrollStepInPx="50" delayInMs="16.66" />
            </React.Fragment>
        );
    }
}
 
export default HomeDefault;