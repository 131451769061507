import React from 'react';
import { Link } from 'react-router-dom';
import lax from 'lax.js';
import LaxDiv from '../Shared/LaxDiv';
 
class Speakers extends React.Component {

    constructor(props) {
        super(props)
        lax.setup()
    
        document.addEventListener('scroll', function(x) {
            lax.update(window.scrollY)
        }, false)
    
        lax.update(window.scrollY)
    }

    render(){
        return (
            <section className="speakers-area-two" style={{paddingTop:30}}  id="speaker">
                <div className="container">
                    <div className="section-title">
                      
                        <h2 style={{color:"#3DB174"}}>Experience the Jamboree Magic!</h2>
                        <h3>Celebrating 8 Years of Innovation</h3>
                        <div className="bar"></div>
                        {/* <LaxDiv text="Speakers" dataPreset="driftRight" /> */}
                        {/* <Link to="#" className="btn btn-primary">View More Speakers</Link> */}
                    </div>

                    <div className="row">
                    <div className="col-lg-8 col-sm-6" style={{fontSize:18}}>
                            {/* <div className="single-speakers-box"> */}
                            <p style={{fontSize:16}}>Get ready to unleash your inner sparkle and join us for the most thrilling event of the year – Netradyne Jamboree 2023 ! Here's to many more years of making the world safer and more efficient with cutting-edge technology. We are excited to invite you and your dearest one to this unforgettable gathering of fun, excitement, and camaraderie.</p>
                                <p style={{fontSize:16}}>Join us in celebrating our journey marked by innovation, safety, and excellence over the last eight years. Thank you for being a part of our success story!
In the meantime, make sure to mark 4th of November on your calendar. We promise it will be an unforgettable experience that you won't want to miss.
</p>
                               <p style={{fontSize:16}}>Warm Wishes,<br/>Team Netradyne.</p>
                               {/* <p></p> */}
                               {/* <p>Despite the ongoing challenges in the marketplace, I am thrilled to see the positive momentum in our Hitachi Vantara partner business as we head into 2023. Together, we have the power to address the most critical business challenges surrounding agility, data access, insights, and hybrid cloud strategy. Our goal is to provide predictable, stable, and efficient solutions that empower businesses to thrive.</p> */}
                               {/* <p>The Hitachi Vantara India Partner Summit has firmly established itself as a must-attend conference for our partners. This year, the event will be held from July 12th to 14th in the enchanting city of Siliguri, West Bengal. It presents a unique opportunity for you to gain front-row access to emerging technologies such as cloud, object storage, Internet of Things (IoT), and much more.</p> */}
                              {/* <p>As a valued partner, your participation in this summit is an opportunity to expand your value by leveraging the diverse range of Hitachi Vantara offerings. You will have the chance to meet and interact with our esteemed leadership team and forge collaborative alliances with fellow partners, enabling us to jointly sell our innovative solutions.</p> */}
                           {/* <p>We firmly believe that partnering with Hitachi Vantara brings together the best of both worlds – leading technology, rich incentives for greater profitability, and a spirit of collaboration and teamwork that will undoubtedly propel you to success.</p> */}
                             {/* <p>We are excited to have you join us at the Hitachi Vantara India Partner Summit 2023. Let us seize this opportunity to shape the future of business together. Together, we can achieve remarkable heights.</p> */}
                                {/* <div className="speakers-image">
                                    <img 
                                        src={require("../../assets/images/speakers1.jpg")}
                                        alt="speaker" 
                                    />
                                </div>

                                <div className="speakers-content">
                                    <h3><Link to="#">James Anderson</Link></h3>
                                    <span>Founder & CEO</span>

                                    
                                </div> */}
                            {/* </div> */}
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="">
                                <div className="speakers-image">
                                    <img 
                                        src={"/sideimage1.png"}
                                        alt="speaker" 
                                    />
                                </div>

                                {/* <div className="speakers-content">
                                    <h3><Link to="#">Hemant Tewari</Link></h3>
                                    <span>Head of Commercial Sales, APAC</span>

                                    
                                </div> */}
                            </div>
                        </div>

                        
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Speakers;