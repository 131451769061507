// Initialize Firebase

import * as firebase from "firebase/app"
import 'firebase/analytics'
import 'firebase/functions'
import 'firebase/database'

// production code for console.log
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  console.log("Development");
} else {
  console.log("Production");
  console.log = () => {};

}
const config = {
  apiKey: "AIzaSyB5QaTAUsv7tqvN6Z177uRqOi72nCAs0ow",
  authDomain: "netradynejamboree2023.firebaseapp.com",
  databaseURL: "https://netradynejamboree2023-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "netradynejamboree2023",
  storageBucket: "netradynejamboree2023.appspot.com",
  messagingSenderId: "130586169642",
  appId: "1:130586169642:web:b17b1b2efe1511f951ce53",
  measurementId: "G-XMKE00TZ3C"
};
firebase.initializeApp(config)
firebase.analytics();